import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { NOTIFICATIONS } from '../utils/FrontEndConstants'
import { notificationOpenedThunk } from '../redux/slices/NotificationsSlice.js'
import { useGetCoursesQuery, useGetUserQuery, useUpdateCourseLastInteractedMutation, useUpdateTrainingLessonStatusMutation } from '../redux/API.js'

function CourseViewerSingle () {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [sentOnce, setSentOnce] = useState(false)

  const [selectedCourse, setSelectedCourse] = useState({})

  const { token } = useSelector((state) => state.core)
  const { combinedContentMap } = useSelector((state) => state.lms)
  const { isLoading: isLoadingTrainings } = useGetCoursesQuery(token)
  const { isLoading: isLoadingUser, data: user, error } = useGetUserQuery(token)
  const [updateTrainingLessonStatus] = useUpdateTrainingLessonStatusMutation()
  const [updateCourseLastInteracted] = useUpdateCourseLastInteractedMutation()

  const isLoading = isLoadingTrainings || isLoadingUser

  useEffect(() => {
    const userCourseData = combinedContentMap[params.courseId]
    setSelectedCourse(userCourseData)
  }, [user])

  useEffect(() => {
    if (error) {
      // Redirect to user to landing page if there is an error with loading their data
      dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.WARNING, alertMessage: 'Your training link has expired. Please enter your email for a new link.' }))
      navigate('/')
    }
  }, [error])

  function getThresholdIndex (progress) {
    const progressThresholds = [0, 0.25, 0.50, 0.75, 0.95]
    let index = 0
    progressThresholds.forEach((threshold, i) => {
      if (progress > threshold) {
        index = i
      }
    })
    return index
  }

  useEffect(() => {
    const player = document.getElementById('player')

    // Set the lastInteractionTime
    updateCourseLastInteracted({ token, courseId: params.courseId })

    if (document.getElementById('player')) {
      let lastThreshold = -1

      player.ontimeupdate = async () => {
        const progress = player.currentTime / player.duration
        const thresholdIndex = getThresholdIndex(progress)
        if (lastThreshold < thresholdIndex) {
          lastThreshold = thresholdIndex
          if (progress >= 0.95) {
            if (!sentOnce) {
              try {
                await updateTrainingLessonStatus({ token, courseId: params.courseId }).unwrap()
                dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.SUCCESS, alertMessage: 'Course Complete! You may change windows.' }))
                setSentOnce(true)
              } catch (err) {
                dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.SUCCESS, alertMessage: 'An error occurred marking this course as complete. Please try again or contact your Security Admin for assistance.' }))
              }
            }
          }
        }
      }
    }
  }, [])

  return (
    <>
      {isLoading &&
        <div>loading</div>}
      {!isLoading &&
        <div className='main container-padding-smallish'>
          <video className='video-center' id='player' width='100%' controls>
            <source src={`/api/customer-resources/${encodeURIComponent(`companies/${user.companyId}/courses/${selectedCourse.courseId}/video.mp4`)}`} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        </div>}
    </>
  )
}

export default CourseViewerSingle

import {
  Routes,
  Route
} from 'react-router-dom'
import '../LMSMain.css'
import LearningMoment from '../pages/LearningMoment'
import Leaderboard from '../pages/Leaderboard'
import LandingPage from '../pages/LandingPage'
import LMSRouter from '../routes/LmsRouter'

function BaseRouter () {
  return (
    <Routes>

      <Route
        path='/:learningMomentCode/:token'
        element={
          <LearningMoment />
        }
      />

      <Route
        path='/courses/:token/*'
        element={
          <LMSRouter />
        }
      />

      <Route
        path='/:token/*'
        element={
          <LMSRouter />
        }
      />

      <Route
        path='/leaderboard/:token'
        element={
          <Leaderboard />
        }
      />

      <Route
        exact path='/'
        element={
          <LandingPage />
        }
      />
    </Routes>
  )
}

export default BaseRouter

import '../../LMSMain.css'

function Tooltip ({ hoverElm, toolTipText }) {
  return (
    <>
      <div className='hover-detail'>
        {hoverElm}
        <span className='hover-popup'>{toolTipText}</span>
      </div>
    </>
  )
}

export default Tooltip

import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Chart from 'chart.js/auto'

import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import $ from 'jquery'
import { CircularProgress, Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useGetLeaderboardDataQuery } from '../redux/API'
import { setTokenAction } from '../redux/slices/CoreSlice'

function Leaderboard () {
  const params = useParams()
  const dispatch = useDispatch()
  const [activeUsers, setActiveUsers] = useState()
  const [phishPercentage, setPhishPercentage] = useState()
  const [learningMoment, setLearningMomentPercentage] = useState()
  const [companyName, setCompanyName] = useState()

  const { token } = useSelector((state) => state.core)
  const { data: leaderboardData, isLoading, isError } = useGetLeaderboardDataQuery(token, { skip: !token })

  useEffect(() => {
    if (isLoading || isError || !token) {
      return
    }

    const { recent, phished, lm, sortedRecords, phishProneData, companyName } = leaderboardData

    setActiveUsers(recent)
    setPhishPercentage(phished)
    setLearningMomentPercentage(lm)
    setCompanyName(`${companyName} Company Leaderboard`)

    // NOTE: When react re-renders the page, it doesn't delete the element from the DOM by the time we try to render the chart below so we destroy it and re-add to the DOM dynamically to make Chart.js happy
    $('#myChart').remove()
    $('.chart-parent').append("<canvas className='my-4 h-100 w-100' id='myChart' />")
    const ctx = document.getElementById('myChart')
    const safeZone = new Array(phishProneData.data.length).fill(7)
    const chartConfig = {
      type: 'line',
      data: {
        labels: phishProneData.labels,
        datasets: [
          {
            data: phishProneData.data,
            lineTension: 0,
            backgroundColor: 'transparent',
            borderColor: '#007bff',
            borderWidth: 4,
            label: 'Phish Percentage',
            pointBackgroundColor: '#007bff'
          }, {
            label: 'Safe Zone',
            lineTension: 0,
            backgroundColor: 'rgb(50,205,50,.6)',
            borderWidth: 4,
            data: safeZone
          }
        ]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        },
        legend: {
          display: true
        }
      }
    }

    const myChart = new Chart(ctx, chartConfig)

    const data = []
    for (let i = 0; i < sortedRecords.length; i++) {
      data.push(sortedRecords[i][1])
    }

    $('#myTable').DataTable({
      order: [[2, 'asc']],
      data,
      columns: [
        { data: 'first', title: 'First Name' },
        { data: 'last', title: 'Last Initial' },
        { data: 'metric', title: 'Grade' }
      ]
    })
  }, [leaderboardData])

  useEffect(() => {
    dispatch(setTokenAction(params.token))

    const root = document.getElementById('root')
    root.style.backgroundColor = 'white'
  }, [])

  // If we couldn't find leaderboard data (or it failed) show the error screen
  if (isError) {
    return (
      <div className='main container-padding-smallish'>
        <Grid
          container
          direction='column'
          justifyContent='start'
          alignItems='center'
        >
          <h2>We couldn't find a leaderboard at this link</h2>
          <p>Make sure the link is correct. If you're still having trouble, let your administrator know.
          </p>
        </Grid>
      </div>
    )
  }

  return (
    <div style={{ maxWidth: '10000px', width: '100vw', background: 'white' }} className='container px-5'>

      <div>
        <section>
          <div className='row'>
            <div className='col text-center'>
              <h1 id='companyName'>{companyName}</h1>
            </div>
          </div>
        </section>
        <section className='mb-2'>
          <div className='row'>

            <div className='col-4 mb-2'>
              <div className='card'>
                <div className='card-body'>
                  <div className='d-flex justify-content-between px-md-1'>
                    <div>
                      <h3 className='text-success' id='activeUsers'>
                        {isLoading &&
                          <CircularProgress />}
                        {activeUsers}
                      </h3>
                      <p id='cardHeader' className='mb-0'>Active Users</p>
                    </div>
                    <div className='align-self-center'>
                      <i className='far fa-user text-info fa-4x' />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-4 mb-2'>
              <div className='card'>
                <div className='card-body'>
                  <div className='d-flex justify-content-between px-md-1'>
                    <div>
                      <h3 className='text-success' id='phishPercentage'>
                        {isLoading &&
                          <CircularProgress />}
                        {phishPercentage}
                      </h3>
                      <p id='cardHeader' className='mb-0'>Number of People Phished</p>
                    </div>
                    <div className='align-self-center'>
                      <i className='fas fa-fish text-success fa-4x' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-4 mb-2'>
              <div className='card'>
                <div className='card-body'>
                  <div className='d-flex justify-content-between px-md-1'>
                    <div>
                      <h3 id='lmPercentage' className='text-success'>
                        {isLoading &&
                          <CircularProgress />}
                        {learningMoment}
                      </h3>
                      <p id='cardHeader' className='mb-0'>People Completing Learning Moments</p>
                    </div>
                    <div className='align-self-center'>
                      <i className='far fa-user text-warning fa-4x' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='my-4'>
          <div className='row'>
            <div className='col-5'>
              <div className='card p-2'>
                <div className='card-header py-3'>
                  <h5 className='mb-0 text-center'><strong>Top Performers</strong></h5>
                </div>

                <div className='card-body'>
                  {isLoading &&
                    <div className='d-flex justify-content-center container-padding-smallish'>
                      <CircularProgress />
                    </div>}
                  <table style={{ width: '100%' }} id='myTable' className='display' />
                </div>
              </div>
            </div>

            <div className='col-7'>
              <div className='card p-2'>
                <div className='card-header py-3'>
                  <h5 className='mb-0 text-center'><strong>Organizational Performance</strong></h5>
                </div>
                <div className='card-body h-100 chart-parent'>
                  {isLoading &&
                    <div className='d-flex justify-content-center container-padding-smallish'>
                      <CircularProgress />
                    </div>}
                  <canvas className='my-4 h-100 w-100' id='myChart' />
                </div>
              </div>
            </div>

          </div>
        </section>
      </div>
    </div>
  )
}

export default Leaderboard

import { createSlice } from '@reduxjs/toolkit'

export const coreSlice = createSlice({
  name: 'core',
  initialState: {
    token: undefined,
    isLMS: false,
    darkMode: false,
    todoItemCount: 0
  },
  reducers: {
    setTokenAction: (state, action) => {
      state.token = action.payload
    },
    setIsLMSAction: (state, action) => {
      state.isLMS = action.payload
    },
    setDarkModeAction: (state, action) => {
      state.darkMode = action.payload
    },
    setTodoItemCountAction: (state, action) => {
      state.todoItemCount = action.payload
    }
  }
})

export const {
  setTokenAction,
  setIsLMSAction,
  setDarkModeAction,
  setTodoItemCountAction,
  setAllowsLocalStorageAction
} = coreSlice.actions

export default coreSlice.reducer

import { IoHandRight } from 'react-icons/io5'
import '../../LMSMain.css'

function IEWarning () {
  return (
    <div className='warning-card'>
      <div className=''>
        <h2 className='warning-card-title'>Unsupported Browser</h2>
        <h3 className='warning-card-text'>We have detected you are using an unsupported browser.
          Please open this link in another application such as Google Chrome, Mozilla Firefox, Apple Safari, or Microsoft Edge.
        </h3>
        <div className='d-flex flex-row justify-content-center'>
          <IoHandRight />
        </div>
      </div>
    </div>
  )
}

export default IEWarning

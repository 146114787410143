import { createSlice } from '@reduxjs/toolkit'
import { setCourseProgressAction } from './LMSSlice'

export const courseViewerSlice = createSlice({
  name: 'courseViewer',
  initialState: {
    lessonLocation: undefined,
    sessionTime: undefined,
    suspendData: undefined
  },
  reducers: {
    setLessonLocationAction: (state, action) => {
      state.lessonLocation = action.payload
    },
    setSessionTimeAction: (state, action) => {
      state.sessionTime = action.payload
    },
    setSuspendDataAction: (state, action) => {
      state.suspendData = action.payload
    }
  }
})

export const {
  setLessonLocationAction,
  setSessionTimeAction,
  setSuspendDataAction
} = courseViewerSlice.actions

export const clearCourseStoreThunk = () => {
  return async (dispatch) => {
    dispatch(setSessionTimeAction(undefined))
    dispatch(setLessonLocationAction(undefined))
    dispatch(setSuspendDataAction(undefined))
  }
}

export const setCombinedContentMapThunk = ({ lessonLocation, suspendData, courseId }) => {
  return async (dispatch, getState) => {
    dispatch(setCourseProgressAction({ lessonLocation, suspendData, courseId }))
    dispatch(setLessonLocationAction(lessonLocation))
    dispatch(setSuspendDataAction(suspendData))
  }
}

export default courseViewerSlice.reducer

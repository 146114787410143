import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// Note token should be migrated to be sent in a header so we don't have to query every single request
// Will require a backend upgrade, beyond the scope of this
export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: (headers, { getState }) => {
      headers.set('Content-Type', 'application/json')
      return headers
    }
  }),
  tagTypes: ['Policies', 'Courses'],
  endpoints: builder => ({
    getCourses: builder.query({
      query: (token) => `/courses?token=${token}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Courses', id })),
              { type: 'Courses', id: 'ALL' }
            ]
          : [{ type: 'Courses', id: 'ALL' }] // Even if we error we still want the ALL case to be valid
    }),
    getPolicies: builder.query({
      query: (token) => `/policies?token=${token}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Policies', id })),
              { type: 'Policies', id: 'ALL' }
            ]
          : [{ type: 'Policies', id: 'ALL' }]
    }),
    getUser: builder.query({
      query: (token) => `/user?token=${token}`
    }),
    getLearningMoment: builder.query({
      query: (token) => `/learning-moment?token=${token}`
    }),
    getLeaderboardData: builder.query({
      query: (token) => `/leaderboard/${token}?token=${token}`
    }),
    updateCourseLastInteracted: builder.mutation({
      query ({ token, courseId }) {
        return {
          url: `/courses/${courseId}/lastInteracted?token=${token}`,
          method: 'PUT'
        }
      }
    }),
    updateTrainingLessonStatus: builder.mutation({
      query ({ token, courseId }) {
        return {
          url: `/lms/lesson-status?token=${token}&course=${courseId}`,
          method: 'PUT',
          body: JSON.stringify({ value: 'passed' })
        }
      },
      invalidatesTags: (result, error, { courseId }) => [{ type: 'Courses', id: courseId }]
    }),
    updatePolicyStatus: builder.mutation({
      query ({ token, policyId }) {
        return {
          url: `/policies/${policyId}/acknowledge?token=${token}`,
          method: 'PUT',
          body: JSON.stringify({ value: 'passed' })
        }
      },
      invalidatesTags: (result, error, { policyId }) => [{ type: 'Policies', id: policyId }]
    })
  })
})

export const {
  useGetCoursesQuery,
  useGetPoliciesQuery,
  useGetUserQuery,
  useGetLearningMomentQuery,
  useGetLeaderboardDataQuery,
  useUpdateTrainingLessonStatusMutation,
  useUpdateCourseLastInteractedMutation,
  useUpdatePolicyStatusMutation
} = apiSlice

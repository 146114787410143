function isEmail (email) {
  const emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
  if (email !== '' && email.match(emailFormat)) { return true }

  return false
}

module.exports.isEmail = isEmail

function determineColor (darkMode, activeFilter) {
  if (darkMode) {
    if (activeFilter) {
      return 'darkModeSecondary'
    } else {
      return 'darkModePrimary'
    }
  } else {
    if (activeFilter) {
      return 'lightModeSecondary'
    } else {
      return 'lightModePrimary'
    }
  }
}

module.exports.determineColor = determineColor

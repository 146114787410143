import { Alert, Button, Grid, TextField } from '@mui/material'
import { useState } from 'react'
import { sendLMSLinkThunk } from '../redux/slices/LMSSlice'
import { useDispatch, useSelector } from 'react-redux'
import { determineColor, isEmail } from '../utils/HelperFunctions'
import { NOTIFICATIONS } from '../utils/FrontEndConstants'
import { notificationOpenedThunk } from '../redux/slices/NotificationsSlice'

function LandingPage () {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [successMessage, setSuccessMessage] = useState(null)
  const [isButtonActive, setButtonActive] = useState(true)
  const dispatch = useDispatch()

  const { darkMode } = useSelector(state => state.core)

  async function sendLMSLink () {
    if (email !== '') {
      if (!isEmail(email)) {
        setError('Please enter a valid email address')
        return false
      }
      setEmail('')
      setError('')

      const lowerCasedEmail = email.toLowerCase()

      setButtonActive(false)
      await dispatch(sendLMSLinkThunk({ email: lowerCasedEmail }))

      setSuccessMessage('Email Sent. Please check your inbox for a new link to your training content.')

      await dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.SUCCESS, alertMessage: 'Request Received.' }))
      setButtonActive(true)
    }
  }

  return (
    <>
      <div className='main container-padding-smallish'>
        <Grid
          container
          direction='column'
          justifyContent='start'
          alignItems='center'
        >
          <h2 className='margin-top:3'>Welcome to Your Security Awareness Training Platform</h2>

          <p className='margin-top:0'>Enter your email address below to get a link to your training content.</p>
          <div style={{ width: '50%', display: 'flex', alignItems: 'start', paddingTop: '8px' }}>
            <div style={{ width: '100%', paddingRight: '24px' }}>
              <TextField
                sx={{ backgroundColor: 'white', borderRadius: '4px' }}
                id='reset-password-email'
                aria-label='get link to lms'
                key='reset-password-email'
                fullWidth
                error={error}
                value={email}
                inputProps={{ maxLength: 255 }}
                onChange={e => {
                  if (error) {
                    setError('')
                  }
                  if (successMessage) {
                    setSuccessMessage(null)
                  }
                  setEmail(e.target.value)
                }}
              />
            </div>

            <Button
              sx={{ marginTop: '8px', width: '150px', borderRadius: '8px', height: '42.25px' }}
              id='get-link-button'
              aria-label='false-option'
              size='large'
              variant='contained'
              onClick={() => { sendLMSLink() }}
              color={determineColor(darkMode, true)}
              disabled={!isButtonActive}
            >Get Link
            </Button>

          </div>

          {error && (
            <Grid container direction='row' alignItems='center' justifyContent='center'>
              <Alert
                id='request-error-banner'
                aria-label='Phin Alert Email Form Warning'
                variant={darkMode ? 'standard' : 'filled'}
                severity='warning'
                sx={{ width: '300px', borderRadius: '8px', marginTop: '32px' }}
              >
                {error}
              </Alert>
            </Grid>
          )}

          {successMessage && (
            <Grid container direction='row' alignItems='center' justifyContent='center'>
              <Alert
                id='request-received-banner'
                aria-label='Request Received Banner'
                variant={darkMode ? 'standard' : 'filled'}
                severity='success'
                sx={{ width: '300px', borderRadius: '8px', marginTop: '32px' }}
              >
                {successMessage}
              </Alert>
            </Grid>
          )}
        </Grid>

      </div>
    </>
  )
}

export default LandingPage

import { Box, Typography } from '@mui/material'
import React from 'react'
import badge from '../../resources/badge.svg'
import '../../css/MyGrowth.css'

export default function CompletedCourseRow ({ key, courseTopic, completedCourses, winningTopic, darkMode }) {
  return (
    <Box key={key} sx={{ display: 'flex', flexDirection: 'cloumn' }}>
      <Typography variant='p' className='topic-name'>
        {courseTopic}
      </Typography>
      <Box className='progress-row' sx={{ maginRight: winningTopic && '1rem' }}>
        {Array.from(Array(completedCourses[courseTopic]).keys()).map((count) => (
          <Box className='progress-cube' key={count} sx={{ backgroundColor: darkMode ? '#F3DE8A' : '#137586' }} />
        ))}
        {courseTopic === winningTopic && <img src={badge} width='25px' style={{ marginBottom: '.5rem' }} />}
      </Box>
    </Box>
  )
}

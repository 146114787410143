import { configureStore } from '@reduxjs/toolkit'
import NotificationsSlice from './slices/NotificationsSlice.js'
import CoreSlice from './slices/CoreSlice.js'
import LMSSlice from './slices/LMSSlice.js'
import courseViewerSlice from './slices/CourseViewerSlice.js'
import { apiSlice } from './API.js'

const ReduxStore = configureStore({
  reducer: {
    notifications: NotificationsSlice,
    core: CoreSlice,
    lms: LMSSlice,
    courseViewer: courseViewerSlice,
    [apiSlice.reducerPath]: apiSlice.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(apiSlice.middleware)
})

export default ReduxStore

import Details from './components/Details'
import UserFeed from './components/UserFeed'
import '../LMSMain.css'
import { useEffect, useState } from 'react'
import { Button, ButtonGroup, Grid } from '@mui/material'
import { useGetPoliciesQuery, useGetCoursesQuery, useGetUserQuery } from '../redux/API'
import { useDispatch, useSelector } from 'react-redux'
import { determineColor } from '../utils/HelperFunctions'
import { useNavigate } from 'react-router'
import { NOTIFICATIONS } from '../utils/FrontEndConstants'
import { notificationOpenedThunk } from '../redux/slices/NotificationsSlice'

function Home () {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [selectedContent, setSelectedContent] = useState()
  const [feedContents, setFeedContents] = useState([])
  const [activeFilter, setActiveFilter] = useState('assigned')
  const { token, darkMode } = useSelector((state) => state.core)
  const { combinedSortedContentArray } = useSelector((state) => state.lms)
  const { isLoading: isLoadingTrainings } = useGetCoursesQuery(token, { skip: !token })
  const { isLoading: isLoadingPolicies } = useGetPoliciesQuery(token, { skip: !token })
  const { data: user, isLoading: isLoadingUser, error } = useGetUserQuery(token, { skip: !token })

  const isLoading = isLoadingPolicies || isLoadingTrainings || isLoadingUser

  useEffect(() => {
    if (activeFilter === 'assigned') {
      const incompleteAssignments = combinedSortedContentArray.filter(content => (content.lesson_status !== 'passed' && content.acknowledged !== true))
      setFeedContents(incompleteAssignments)
    } else if (activeFilter === 'complete') {
      const completedAssignments = combinedSortedContentArray.filter(content => (content.lesson_status === 'passed' || content.acknowledged === true))
      setFeedContents(completedAssignments)
    } else {
      setFeedContents(combinedSortedContentArray)
    }
  }, [activeFilter, combinedSortedContentArray])

  useEffect(() => {
    if (error) {
      // Redirect to user to landing page if there is an error with loading their data
      dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.WARNING, alertMessage: 'Your training link has expired. Please enter your email for a new link.' }))
      navigate('/')
    }
  }, [error])

  return (
    <div className='main'>
      {(selectedContent) &&
        <div className='no-padding details-window accent'>
          <Details
            selectedContent={selectedContent}
            setSelectedContent={setSelectedContent}
            user={user}
            darkMode={darkMode}
          />
        </div>}
      <Grid
        container
        direction='column'
        justifyContent='start'
        alignItems='center'
      >
        <div className='container-padding-smallish feed'>
          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
          >
            <ButtonGroup
              sx={{ borderRadius: '16px', padding: '8px', backgroundColor: 'var(--main)' }}
              variant='contained'
              aria-label='Select Content View'
              color={determineColor(darkMode)}
            >
              <Button
                size='large'
                sx={{ borderRadius: '8px 0 0 8px', '&:focus': { outline: 'none' } }}
                disableRipple
                aria-label='Select Assigned Content View Button'
                color={determineColor(darkMode, activeFilter === 'assigned')}
                id='switch-to-assigned-view-button'
                onClick={() => setActiveFilter('assigned')}
              >Assigned
              </Button>
              <Button
                size='large'
                sx={{ borderRadius: '0', '&:focus': { outline: 'none' } }}
                disableRipple
                aria-label='Select Completed Content View Button'
                color={determineColor(darkMode, activeFilter === 'complete')}
                id='switch-to-completed-view-button'
                onClick={() => setActiveFilter('complete')}
              >Completed
              </Button>
              <Button
                size='large'
                sx={{ borderRadius: '0 8px 8px 0', '&:focus': { outline: 'none' } }}
                disableRipple
                aria-label='Select All Content View Button'
                color={determineColor(darkMode, activeFilter === 'all')}
                id='switch-to-all-view-button'
                onClick={() => setActiveFilter('all')}
              >All
              </Button>
            </ButtonGroup>
            <UserFeed content={feedContents} activeFilter={activeFilter} setSelectedContent={setSelectedContent} loading={isLoading} />
          </Grid>
        </div>
      </Grid>
    </div>
  )
}

export default Home

import { Box, CircularProgress, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import '../css/MyGrowth.css'
import moment from 'moment'
import CompletedCourseRow from './components/CompletedCourseRow'
import { useSelector } from 'react-redux'

const CUSTOM_COURSE_TOPIC = 'Custom'

export default function MyGrowthViewer () {
  const [completedCourses, setCompletedCourses] = useState({})
  const [finishedContentThisYear, setFinishedContentThisYear] = useState([])
  const [winningTopic, setWinningTopic] = useState('')

  const { darkMode } = useSelector((state) => state.core)
  const { combinedSortedContentArray } = useSelector((state) => state.lms)

  useEffect(() => {
    const finishedTopicsMap = {}
    // set page view to display finished content for this year only
    const finishedContentCurrentYear = combinedSortedContentArray.filter((content) => {
      const formattedDateCompleted = content.dateCompleted ? moment(content.dateCompleted._seconds * 1000).format('YYYY') : null
      const currentDate = new Date().getFullYear().toString()
      const courseIsCurrentYear = formattedDateCompleted === currentDate

      if (courseIsCurrentYear) {
        // If the topic exists, add to the finishedTopicsMap and increment
        if (content.topic) {
          if (finishedTopicsMap[content.topic]) {
            finishedTopicsMap[content.topic] = finishedTopicsMap[content.topic] + 1
          } else {
            finishedTopicsMap[content.topic] = 1
          }
          return true
        } else {
          // If the topic does NOT exist, add a new 'Custom' topic and increment
          if (!finishedTopicsMap[CUSTOM_COURSE_TOPIC]) {
            finishedTopicsMap[CUSTOM_COURSE_TOPIC] = 0
          }

          finishedTopicsMap[CUSTOM_COURSE_TOPIC]++
          return true
        }
      }

      return false
    })

    // sort our topics map from most to least
    const sortedTopicsMap = Object.fromEntries(
      Object.entries(finishedTopicsMap).sort(([, b], [, a]) => a - b)
    )

    // find the winning topic to put the badge on by determining which value is the highest
    if (Object.keys(sortedTopicsMap).length > 0) {
      const mostCompletedTopic = Object.keys(sortedTopicsMap)[0]
      setWinningTopic(mostCompletedTopic)
    }

    setCompletedCourses(sortedTopicsMap)
    setFinishedContentThisYear(finishedContentCurrentYear)
  }, [combinedSortedContentArray])

  if (combinedSortedContentArray?.length === 0) {
    return (
      <Box className='my-growth-container' sx={{ background: darkMode ? '#052460' : '#C7E8E8', color: darkMode && 'white' }}>
        <CircularProgress sx={{ marginTop: '5rem', color: darkMode ? 'white' : 'black' }} />
      </Box>
    )
  }

  return (
    <Box className='my-growth-container' sx={{ background: darkMode ? '#052460' : '#C7E8E8', color: darkMode && 'white' }}>
      <Box className='stats-container'>
        <Box className='title-container'>
          <Typography variant='h5' className='bold'>My Growth</Typography>
          <Typography variant='p' component='p' className='bold'>{`(${new Date().getFullYear()})`}</Typography>
        </Box>
        <Box className='sub-heading-container'>
          <Typography variant='h5' className='bold' sx={{ marginRight: '.5rem' }}>{finishedContentThisYear.length}</Typography>
          <Typography variant='p' component='p' className='bold'>{finishedContentThisYear.length === 1 ? 'Course' : 'Courses'} Complete</Typography>
        </Box>
        <Box className='breakdown-divider' sx={{ borderBottom: `2px solid ${darkMode ? 'white' : 'black'}` }}>
          <Box>
            <Typography variant='h6' className='bold'>Security Topic Breakdown</Typography>
          </Box>
        </Box>
        {!finishedContentThisYear.length && <Typography variant='p' component='p'> No topics covered yet</Typography>}
        {Object.keys(completedCourses).length > 0 && Object.keys(completedCourses).map((courseTopic) => (
          <CompletedCourseRow
            key={courseTopic}
            courseTopic={courseTopic}
            winningTopic={winningTopic}
            completedCourses={completedCourses}
            darkMode={darkMode}
          />
        ))}
      </Box>
    </Box>
  )
}

import { createTheme } from '@mui/material'

export const phinTheme = createTheme({
  palette: {
    // This is the main action color.
    // This should be used for buttons, links, and other interactive elements as the primary color
    primary: {
      main: '#39A0FF',
      light: '#EAF5FF',
      dark: '#0057B2',
      contrastText: '#fff'
    },
    warning: {
      main: '#F3DE8A',
      light: '#f8ebb9',
      dark: '#c2b16f',
      contrastText: '#000'
    },
    phinGray: {
      main: '#8C8C8C',
      light: '#D8D8D8',
      dark: '#606060',
      contrastText: '#fff'
    },
    phinBlack: {
      main: '#000000',
      light: '#333333',
      dark: '#000000',
      contrastText: '#fff'
    },
    phinYellow: {
      main: '#FFC400',
      light: '#FFF8E1',
      dark: '#e0ac00',
      contrastText: '#fff'
    },
    phinWhite: {
      main: '#FFFFFF',
      light: '#FFFFFF',
      dark: '#FFFFFF',
      contrastText: '#000'
    },
    phinGreen: {
      main: '#3CA03A',
      light: '#D1EDC0',
      dark: '#1B5E20',
      contrastText: '#fff'
    },
    phinSecondary: {
      main: '#433E99',
      light: '#7b78b8',
      dark: '#221F4D',
      contrastText: '#fff'
    },
    lightModePrimary: {
      main: '#137586',
      light: '#C7E8E8',
      dark: '#146a79',
      contrastText: '#FFF'
    },
    lightModeSecondary: {
      main: '#052460',
      light: '#0944b5',
      dark: '#093690',
      contrastText: '#FFF'
    },
    darkModePrimary: {
      main: '#09348a',
      light: '#0f54df',
      dark: '#0d49c2',
      contrastText: '#FFF'
    },
    darkModeSecondary: {
      main: '#F3DE8A',
      light: '#f8ebb9',
      dark: '#c2b16f',
      contrastText: '#000'
    }
  }
})

import { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import BaseRouter from './routes/BaseRouter'
import 'scorm-again'
import NavigationBar from './pages/components/NavigationBar'
import IEWarning from './pages/components/IEWarning'
import { hasAccessToLocalStorage } from './utils/LocalStorageUtils'
import { NotificationContainer } from './pages/components/notifications/NotificationContainer'
import { phinTheme } from './utils/MuiOverrideThemes'
import { ThemeProvider } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setDarkModeAction } from './redux/slices/CoreSlice'
window.API = new window.Scorm12API({})
window.API.on('LMSInitialize', function () {
})

function App () {
  const isIE = !!window.document.documentMode

  const allowsLocalStorage = hasAccessToLocalStorage()
  const dispatch = useDispatch()
  const { darkMode } = useSelector(state => state.core)

  function loadThemeFromLocalStorage () {
    if (allowsLocalStorage) {
      return localStorage.getItem('darkMode')
    } else {
      return null
    }
  }

  function setThemeToLocalStorage (enableDarkMode) {
    if (allowsLocalStorage) {
      localStorage.setItem('darkMode', enableDarkMode)
    }
  }

  useEffect(() => {
    const darkModeEnabled = loadThemeFromLocalStorage()

    document.querySelector('html').classList.add('theme-light')

    if (darkModeEnabled === null || darkModeEnabled === 'false') {
      dispatch(setDarkModeAction(false))
    } else {
      dispatch(setDarkModeAction(true))
    }
  }, []) // setting a default theme

  useEffect(() => {
    if (!isIE) {
      if (darkMode) {
        document.querySelector('html').classList.replace('theme-light', 'theme-dark')
        setThemeToLocalStorage(true)
      } else {
        document.querySelector('html').classList.replace('theme-dark', 'theme-light')
        setThemeToLocalStorage(false)
      }
    }
  }, [darkMode])

  return (
    <ThemeProvider theme={phinTheme}>
      <NotificationContainer darkMode={darkMode} />
      {isIE && (
        <div className='warning-container'>
          <IEWarning />
        </div>
      )}

      {!isIE && (
        <BrowserRouter>
          <NavigationBar />
          <BaseRouter />
        </BrowserRouter>
      )}
    </ThemeProvider>
  )
}

export default App

import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import LearningMomentViewer from './components/LearningMomentViewer'
import { useDispatch, useSelector } from 'react-redux'
import { setTokenAction } from '../redux/slices/CoreSlice'
import { useGetLearningMomentQuery } from '../redux/API'

function LearningMoment () {
  const params = useParams()
  const dispatch = useDispatch()

  const { token, darkMode } = useSelector((state) => state.core)
  const { isLoading, isError, data: learningMomentData } = useGetLearningMomentQuery(params.token)

  const isLearningMomentValid = !isError // If this learning moment exists the query will not error

  useEffect(() => {
    dispatch(setTokenAction(params.token))
  }, [params.token])

  return (
    <>
      {isLoading &&
        <div>loading</div>}
      {!isLoading && isLearningMomentValid &&
        <>
          <div id='lm' className='main container-padding-smallish justify-content-center'>
            <LearningMomentViewer
              token={token}
              learningMomentData={learningMomentData}
              darkMode={darkMode}
            />
          </div>
        </>}
      {!isLoading && !isLearningMomentValid &&
        <>
          <div className='main container-padding-smallish'>
            Failed to load Learning Moment. Please contact your administrator for assistance.
          </div>
        </>}
    </>
  )
}

export default LearningMoment

import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { notificationOpenedThunk } from '../redux/slices/NotificationsSlice.js'
import { NOTIFICATIONS } from '../utils/FrontEndConstants.js'
import { CircularProgress } from '@mui/material'
import { useGetCoursesQuery, useUpdateCourseLastInteractedMutation, useUpdateTrainingLessonStatusMutation } from '../redux/API.js'
import { buildAssetsPath } from '../utils/ScormUtils.js'
import { clearCourseStoreThunk, setCombinedContentMapThunk, setLessonLocationAction, setSessionTimeAction, setSuspendDataAction } from '../redux/slices/CourseViewerSlice.js'

function CourseViewer () {
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isLmsSetup, setIsLmsSetup] = useState(false)
  const [courseContent, setCourseContent] = useState('')
  let currentSuspend
  let completed = false // non-state flag used to prevent multiple firings of lesson_status, which can be caused by switching languages and completing the course.
  let courseActive = true

  const { token } = useSelector((state) => state.core)
  const { lessonLocation, sessionTime, suspendData } = useSelector((state) => state.courseViewer)
  const { combinedContentMap } = useSelector((state) => state.lms)
  const { isLoading: isLoadingTrainings } = useGetCoursesQuery(token)
  const [updateTrainingLessonStatus] = useUpdateTrainingLessonStatusMutation()
  const [updateCourseLastInteracted] = useUpdateCourseLastInteractedMutation()

  const loading = isLoadingTrainings

  useEffect(() => {
    let courseURL = ''
    if (params.courseId) {
      courseURL = buildAssetsPath(combinedContentMap[params.courseId])
    }

    setCourseContent(courseURL)
  }, [combinedContentMap])

  let interaction = {}
  let questionId = ''

  async function saveUserAssessment ({ element, value }) {
    try {
      // take element and split
      const elementPathArr = element.split('.')

      // get last element
      const lastElement = elementPathArr.pop()

      if (lastElement === 'id') {
        // set the questionId for the current interaction
        questionId = value.split('-').pop()
        interaction = { questionId }
      } else if (lastElement === 'type') {
        interaction.type = value
      } else if (lastElement === 'student_response') {
        interaction.answerId = value
      } else if (lastElement === 'result') {
        interaction.result = value

        try {
          // figure the DOM path for the question in the DOM
          interaction.questionText = document.getElementById('scorm-iframe').contentDocument
            .getElementById(`${questionId}-header`)
            .getElementsByClassName('mcq__body-inner')[0]
            .getElementsByTagName('p')[0].innerText
        } catch (error) {
          console.error(`Could not find questionId:${questionId} element in the DOM for courseId:${params.courseId} and tokenId:${params.token}. `, error)
        }

        try {
          // find the answer text
          interaction.answerText = document.getElementById('scorm-iframe').contentDocument
            .querySelector(`[aria-labelledby="${questionId}-header"]`)
            .getElementsByClassName('is-selected')[0].innerText
        } catch (error) {
          console.error(`Could not find answer from questionId:${questionId} element in the DOM for courseId:${params.courseId} and tokenId:${params.token}. `, error)
        }

        // save to back-end
        const url = `/api/lms/lesson-attempt?token=${token}&course=${params.courseId}`
        await fetch(url, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ interaction })
        })
        interaction = {}
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    const setupLMS = async () => {
      /*
      TODO: Currently, there is a bug where multiple copies of event listeners are being attached which can fire off multiple requests to save user attempts data.
        The isLmsSetup boolean helps gatekeep the event listners from being loaded multiple times.
          We tried the SCORM-again approach with: window.API.off('LMSSetValue.cmi.interactions.*', userAssessmentCB)
            and then separately: window.API.clear('LMSSetValue.cmi.interactions.*')
            before setting the listener with window.API.on('LMSSetValue.cmi.interactions.*', userAssessmentCB)
            but those proved ineffective to remove any existing listeners.
          We are unclear on how to reproduce the issue of multiple listeners being activated, but this gatekeep seemed effective at preventing that from happening.

          UPDATE from 24/26/1: It does not seem like this gatekeep works, but 'completed' variable has been added
          to window.API.on('LMSSetValue.cmi.core.lesson_status' which does not prevent scorm trying to complete
          multiple times, but it does prevent it from successfully running its body more than once
      */
      if (!isLmsSetup && courseContent !== '' && combinedContentMap[params.courseId]) {
        setIsLmsSetup(true)
        dispatch(clearCourseStoreThunk())
        window.API = new window.Scorm12API({})
        const userCourseData = combinedContentMap[params.courseId]
        await updateCourseLastInteracted({ courseId: params.courseId, token }).unwrap()

        window.API.on('LMSInitialize', async function () {
          if (userCourseData.lesson_location) {
            window.API.cmi.core.lesson_location = userCourseData.lesson_location
          }
          if (userCourseData.suspend_data) {
            window.API.cmi.suspend_data = userCourseData.suspend_data
          }
          courseActive = true
        })

        window.API.on('LMSSetValue.cmi.core.lesson_status', async function (element, value) {
          if (!completed && (value === 'passed' || value === 'completed')) {
            try {
              completed = true
              await updateTrainingLessonStatus({ courseId: params.courseId, token }).unwrap() // Unwrap will throw if a failure occured
              dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.SUCCESS, alertMessage: 'Course Complete!' }))
            } catch (error) {
              console.error(`Could not update course status for courseId ${params.courseId} and token ${params.token}`, error)
              dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.ERROR, alertMessage: 'There was an issue saving course data. Please contact your admin' }))
            }
          }
        })

        window.API.on('LMSSetValue.cmi.interactions.*', async function (element, value) {
          await saveUserAssessment({ element, value })
        })

        window.API.on('LMSSetValue.cmi.core.score.*', async function (element, value) {
          const cmi = window.API.cmi
          const scoreCMIPath = element.split('.')
          const scoreSubAttribute = scoreCMIPath[scoreCMIPath.length - 1]
          if (scoreSubAttribute === 'raw') {
            // It is possible that the user leaves the page before the fetch can return.
            // This try-catch prevents uncaught errors (usually inconsequential) from clouding sentry
            try {
              if (params.token && params.courseId) {
                const url = `/api/lms/lesson-score?token=${params.token}&course=${params.courseId}`
                await fetch(url, {
                  method: 'PUT',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({ score: cmi.core.score })
                })
              }
            } catch (error) {
              console.error(`Could not update course score for courseId ${params.courseId} and token ${params.token}`, error)
            }
          }
        })

        window.API.on('LMSSetValue.cmi.core.session_time', async function (element, value) {
          courseActive = true
          dispatch(setSessionTimeAction(value))
        })

        window.API.on('LMSSetValue.cmi.core.lesson_location', async function (element, value) {
          courseActive = true
          dispatch(setLessonLocationAction(value))
        })

        window.API.on('LMSCommit', async function () {
          const suspend = window.API.cmi.suspend_data
          if (currentSuspend !== suspend) {
            currentSuspend = suspend
            try {
              courseActive = true
              dispatch(setSuspendDataAction(window.API.cmi.suspend_data))
            } catch (err) {
              console.error(err)
            }
          }
        })

        window.API.on('LMSFinish', async function () {
          navigate(`/${token}`)
        })

        window.API.on('LMSSetValue.cmi.core.exit', async function (element, value) {
          const updateObject = { lessonLocation: window.API.cmi.lesson_location, suspendData: window.API.cmi.suspend_data, courseId: params.courseId }
          dispatch(setCombinedContentMapThunk(updateObject))

          navigator.sendBeacon(`/api/lms/commit?token=${params.token}&course=${params.courseId}`, JSON.stringify({ lessonLocation, suspendData, sessionTime }))

          dispatch(clearCourseStoreThunk())
          const exit = value
          if (exit !== 'suspend') {
            courseActive = false
            navigate(`/${token}`)
          }
        })
      }
    }

    setupLMS()
  }, [courseContent])

  useEffect(() => {
    return () => {
      courseActive = false
      navigator.sendBeacon(`/api/lms/commit?token=${params.token}&course=${params.courseId}`, JSON.stringify({ lessonLocation, sessionTime, suspendData }))
      dispatch(clearCourseStoreThunk())
    }
  }, [])

  // To save in case of sudden page exit
  document.onvisibilitychange = () => {
    if (document.visibilityState === 'hidden' && courseActive) {
      courseActive = false
      navigator.sendBeacon(`/api/lms/commit?token=${params.token}&course=${params.courseId}`, JSON.stringify({ lessonLocation, sessionTime, suspendData }))
      dispatch(clearCourseStoreThunk())
    }
  }

  return (
    <div className='main'>
      {(loading || !courseContent || courseContent === '') &&
        <div
          style={{ width: '100vw', height: '10vh' }}
          className='d-flex justify-content-center align-items-center container-padding-smallish'
        >
          <CircularProgress />
        </div>}
      {(!loading && courseContent && courseContent !== '') &&
        <div className=' w-100 scorm-content'>
          <iframe src={courseContent} id='scorm-iframe' className='scorm-frame scorm-container w-100' />
        </div>}
    </div>
  )
}

export default CourseViewer

import { Button, Grid, IconButton, Link } from '@mui/material'
import '../../LMSMain.css'
import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { IoClose, IoDocumentText, IoEllipse } from 'react-icons/io5'

function Details ({ selectedContent, user, setSelectedContent, detailsMenuOpen, darkMode }) { // TODO: rework this whole component
  // state set up
  const [userOptions, setUserOptions] = useState(['take'])
  const detailsRef = useRef()
  const params = useParams()
  const navigate = useNavigate()

  // useEffect for each element coming in
  useEffect(() => {
    // setup the options that the user can take on the content
    configureUserOptions(selectedContent, user)
  }, [selectedContent])

  useEffect(() => {
    const hasSomeParentTheClass = (element) => {
      if (element.name === ('course-card' || 'policy-card')) return true
      return element.parentNode && hasSomeParentTheClass(element.parentNode)
    }

    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (detailsRef.current && !detailsRef.current.contains(e.target) && !hasSomeParentTheClass(e.target)) {
        setSelectedContent()
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [detailsRef])

  const takeCourse = (course) => {
    if (course.type === 'single') {
      navigate(`/${params.token}/course-viewer/single/${course.id}`)
    } else if (course.contentType === 'policy') {
      navigate(`/${params.token}/policy-viewer/${course.id}`)
    } else {
      navigate(`/${params.token}/course-viewer/${course.id}`)
    }
  }

  const renderUserActionButtons = (options, user, content) => {
    const elms = []
    options.forEach((option, i) => {
      if (option === 'download certificate') {
        elms.push(
          <Button
            variant='contained'
            color={darkMode ? 'darkModePrimary' : 'lightModePrimary'}
            key={i}
            href={`/api/certificates?courseId=${content.id}&companyId=${user.companyId}&userId=${user.id}`}
            component={Link}
            download={`${content.name}_cert`}
            sx={{ color: 'white !important' }}
          >
            {option}
          </Button>)
      } else if (option === 'take' || option === 'read' || option === 'reread') {
        elms.push(
          <Button
            key={i}
            color={darkMode ? 'darkModePrimary' : 'lightModePrimary'}
            component={Link}
            onClick={() => takeCourse(content)}
            className='button-theme'
            sx={{ color: 'white !important' }}
          >{option}
          </Button>)
      } else {
        elms.push(
          <Button key={i} className='button-theme'>{option}</Button>)
      }
    })
    return elms
  }

  const renderContentStatus = (status) => {
    let color
    if (status === false || status === 'incomplete') {
      color = 'red'
    } else {
      color = 'green'
    }
    return (
      <IoEllipse size='1.25em' style={{ color }} />
    )
  }

  const configureUserOptions = (content) => {
    if (content) {
      if (content.contentType === 'course') {
        if (content.lesson_status === 'incomplete') {
          setUserOptions(['take'])
        } else {
          setUserOptions(['download certificate'])
          // TODO: possibly add functionality for retake, report, more resources
        }
      } else if (content.contentType === 'policy') {
        if (content.acknowledged === false) {
          setUserOptions(['read'])
        } else {
          setUserOptions(['reread']) // removed certificates for policies
        }
      }
    } else {
      console.log('no content is selected')
    }
  }

  return (
    <div ref={detailsRef} className='h-100 lms-plr-1' onClick={() => setSelectedContent()}>
      <Grid
        container
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
      >
        <IconButton aria-label='delete' color='phinWhite'>
          <IoClose />
        </IconButton>
      </Grid>
      <div className=' lms-pt-1'>
        <h4>{selectedContent.name}</h4>
      </div>
      <div className='d-flex justify-content-center'>
        {(selectedContent.thumbnail) &&
          <img className='lms-pt-1 details-img align-self-center' src={selectedContent.thumbnail} />}
        {(!selectedContent.thumbnail) &&
          <IoDocumentText size='8em' style={{ color: 'black' }} />}
      </div>
      {(selectedContent.lesson_status) &&
        <div className='pr-1 lms-pt-1 d-flex flex-row justify-content-start align-items-center'>
          <span>Status: </span>
          {renderContentStatus(selectedContent.lesson_status)}
        </div>}
      {(!selectedContent.lesson_status) &&
        <div className='pr-1 lms-pt-1 d-flex flex-row justify-content-start align-items-center'>
          <span>Acknowledged: </span>
          {renderContentStatus(selectedContent.acknowledged)}
        </div>}

      {(selectedContent.description) &&
        <div className='lms-pt-1 description'> Description:  {selectedContent.description}</div>}
      <div className='lms-pt-2 padding-bottom:-1 d-flex flex-column align-items-end justify-content-end'> <b className='padding-bottom:-1'>User Actions</b>
        {(userOptions) &&
            renderUserActionButtons(userOptions, user, selectedContent)}
      </div>

    </div>
  )
}

export default Details

/**
 * Tests to see if the current browser allows local storage
 * @returns {boolean} Whether or not this browser is allowing access to localStorage
 */
module.exports.hasAccessToLocalStorage = () => {
  const test = 'test'
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

/**
 * Tests to see if the current browser allows local session storage
 * @returns {boolean} Whether or not this browser is allowing access to sessionStorage
 */
module.exports.hasAccessToSessionStorage = () => {
  const test = 'test'
  try {
    sessionStorage.setItem(test, test)
    sessionStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

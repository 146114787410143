import { createSlice } from '@reduxjs/toolkit'

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    open: false,
    alertSeverity: null,
    alertMessage: null
  },
  reducers: {
    notificationOpened: (state, action) => {
      state.open = action.payload.open
      state.alertSeverity = action.payload.alertSeverity
      state.alertMessage = action.payload.alertMessage
    },
    notificationClosed: (state, action) => {
      state.open = false
      state.alertSeverity = null
      state.alertMessage = null
    }
  }
})

export const {
  notificationOpened: notificationOpenedAction,
  notificationClosed: notificationClosedAction
} = notificationsSlice.actions

export default notificationsSlice.reducer

export const notificationOpenedThunk = ({ open, alertSeverity, alertMessage }) => {
  return async (dispatch) => {
    dispatch(notificationClosedAction())
    dispatch(notificationOpenedAction({ open, alertSeverity, alertMessage }))
  }
}

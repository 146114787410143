import {
  Routes,
  Route,
  useParams
} from 'react-router-dom'
import '../LMSMain.css'
import { useEffect } from 'react'
import Home from '../pages/Home'
import CourseViewer from '../pages/CourseViewer'
import CourseViewerSingle from '../pages/CourseViewerSingle'
import PolicyViewer from '../pages/PolicyViewer'
import MyGrowthViewer from '../pages/MyGrowthViewer'
import { useDispatch, useSelector } from 'react-redux'
import { setIsLMSAction, setTokenAction } from '../redux/slices/CoreSlice'
import { useGetPoliciesQuery, useGetCoursesQuery } from '../redux/API'
import { populateContentMaps } from '../redux/slices/LMSSlice'

function LMSRouter () {
  const dispatch = useDispatch()
  const params = useParams()

  const { token } = useSelector((state) => state.core)
  const { data: trainings } = useGetCoursesQuery(token, { skip: token === undefined })
  const { data: policies } = useGetPoliciesQuery(token, { skip: token === undefined })

  useEffect(() => {
    // We've detected a change in the trainings/policies, update our content maps
    if (token) {
      dispatch(populateContentMaps({ trainings, policies }))
    }
  }, [trainings, policies, token, dispatch])

  useEffect(() => {
    dispatch(setIsLMSAction(true))
    dispatch(setTokenAction(params.token))
  }, [dispatch, params])

  return (
    <Routes>
      <Route
        path='/' element={
          <Home />
        }
      />

      <Route
        path='analytics/my-growth'
        element={
          <MyGrowthViewer />
        }
      />

      <Route
        path='/course-viewer/:courseId' element={
          <CourseViewer />
        }
      />

      <Route
        path='/policy-viewer/:policyId' element={
          <PolicyViewer />
        }
      />

      <Route
        path='/course-viewer/single/:courseId' element={
          <CourseViewerSingle />
        }
      />

    </Routes>
  )
}

export default LMSRouter

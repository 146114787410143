import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PDFObject from 'pdfobject/pdfobject.js'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { notificationOpenedThunk } from '../redux/slices/NotificationsSlice.js'
import { NOTIFICATIONS } from '../utils/FrontEndConstants.js'
import { useUpdatePolicyStatusMutation } from '../redux/API.js'

function PolicyViewer () {
  const params = useParams()
  const dispatch = useDispatch()

  const [acknowledged, setAcknowledged] = useState(false)

  const { token } = useSelector((state) => state.core)
  const { combinedContentMap } = useSelector((state) => state.lms)
  const [updatePolicyStatus] = useUpdatePolicyStatusMutation()

  async function getPolicy () {
    const policy = combinedContentMap[params.policyId]
    setAcknowledged(policy.acknowledged)
    PDFObject.embed(`/api/policy-assets/${policy?.policyStoragePath}?token]${token}`, '#policy-frame', {
      fallbackLink: '<h4>Looks like you are viewing this on mobile. <a href="[url]" target="_blank">Click here</a> to open the policy for viewing.</h4>'
    })
  }

  useEffect(() => {
    getPolicy()
  }, [combinedContentMap])

  const submitUserAcknowledge = async (e) => {
    if (acknowledged) {
      e.preventDefault()

      dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.WARNING, alertMessage: 'Policy Already Acknowledged.' }))
    } else {
      try {
        await updatePolicyStatus({ token, policyId: params.policyId }).unwrap()
        dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.SUCCESS, alertMessage: 'Policy acknowledged! You may now change windows.' }))
        setAcknowledged(true)
      } catch (err) {
        dispatch(notificationOpenedThunk({ open: true, alertSeverity: NOTIFICATIONS.ALERT_SEVERITY.ERROR, alertMessage: 'Sorry there was an issue acknowledging this policy. Please try again or contact your Security Admin for assistance.' }))
      }
    }
  }

  return (
    <div className='main container-padding-smallish'>
      <div id='policy-frame' className='w-100' />

      <div className='acknowledge'>
        <div className='ack-mdb-padding-fix'>
          <div className={(acknowledged) ? 'ack-btn-disabled' : 'ack-btn'}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox name='acknowledgeCheckbox' disabled={acknowledged} onClick={(e) => submitUserAcknowledge(e)} id='acknowledgeCheckbox' />
                  }
                label='Acknowledge'
                checked={acknowledged}
              />
            </FormGroup>
          </div>
        </div>
      </div>

    </div>
  )
}

export default PolicyViewer

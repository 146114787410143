import React from 'react'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import './index.css'
import App from './App'
import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'
import ReduxStore from './redux/ReduxStore'
import { Provider } from 'react-redux'

if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
  Sentry.init({
    dsn: 'https://54db390dda044945bef43320e34ab38d@o428197.ingest.sentry.io/5373367',
    environment: process.env.REACT_APP_ENVIRONMENT
  })
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Provider store={ReduxStore}>
    <App />
  </Provider>
)
